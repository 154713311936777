












































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";

@Component({
  components: {},
})
export default class Publicacion extends Vue {
  private nombreWeb: any = "";
  private dataNoticia: any = "";
  private dataNoticiasRelacionadas: any = [];
  private reproducir: boolean = true;
  private speechSynthesisinwindow = false
  private voice: SpeechSynthesisUtterance | null = null;
  private speaking: boolean = false;

  private mounted() {
    if ('speechSynthesis' in window) {
      this.speechSynthesisinwindow = true
    }
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticia();
      this.linkCanonical(this.nombreWeb)
    }
  }
  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/noticias/publicacion/' + nombreWeb; 
    document.head.appendChild(canonicalLink);
  }

  private getInfoNoticia() {
    this.$axios
      .get("noticias/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        // console.log(res.data);
        this.dataNoticia = res.data;
        this.dataNoticiasRelacionadas = this.dataNoticia.relacionadas;
        this.visitaNoticia();
        if (this.dataNoticia.fotos.image_fulltext) {
          this.dataNoticia.fotos.image_fulltext =
            base + this.dataNoticia.fotos.image_fulltext;
        } else {
          this.dataNoticia.fotos.image_fulltext = require("@/assets/logos/logo-actividades.webp");
        }
        if (this.dataNoticia.fotos.image_intro) {
          this.dataNoticia.fotos.image_intro =
            base + this.dataNoticia.fotos.image_intro;
        } else {
          this.dataNoticia.fotos.image_intro = require("@/assets/logos/logo300.webp");
        }

        for (let i = 0; i < this.dataNoticiasRelacionadas.length; i++) {
          if (
            this.dataNoticiasRelacionadas[i].fotos.image_intro &&
            this.dataNoticiasRelacionadas[i].fotos.image_intro != ""
          ) {
            this.dataNoticiasRelacionadas[i].fotos.image_intro =
              base + this.dataNoticiasRelacionadas[i].fotos.image_intro;
          } else {
            this.dataNoticiasRelacionadas[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.webp");
          }
        }

        //MetaData generica
        
        // document.head.innerHTML += '<link rel="canonical" href="https://muniarica.cl/noticias/publicacion/' + this.nombreWeb + '" />';
        document.title = this.dataNoticia.titulo + " - Municipalidad de Arica";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataNoticia.fotos.image_intro
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private speak(title: string, description: string, content: string) {
    if ("speechSynthesis" in window) {
      this.speaking = true;

      let textoSinEtiquetas = content.replace(/<\/?[^>]+>/g, "");
      const textoDecodificado = textoSinEtiquetas
        .replace(/&nbsp;/g, " ")
        .replace(/&aacute;/g, "á")
        .replace(/&eacute;/g, "é")
        .replace(/&iacute;/g, "í")
        .replace(/&oacute;/g, "ó")
        .replace(/&uacute;/g, "ú")
        .replace(/&ntilde;/g, "ñ")
        .replace(/&Aacute;/g, "Á")
        .replace(/&Eacute;/g, "É")
        .replace(/&Iacute;/g, "Í")
        .replace(/&Oacute;/g, "Ó")
        .replace(/&Uacute;/g, "Ú")
        .replace(/&Ntilde;/g, "Ñ")
        .replace(/&uuml;/g, "ü")
        .replace(/&ldquo;/g, "“")
        .replace(/&rdquo;/g, "”");

      this.voice = new SpeechSynthesisUtterance();
      this.voice.lang = "es-MX";
      this.voice.rate = 1;
      this.voice.pitch = 1;
      this.voice.text = `${title}. ${description}. ${textoDecodificado}`;

      this.voice.onstart = () => {
        // console.log("      window.speechSynthesis;", window.speechSynthesis);
        this.speaking = true;
      };

      this.voice.onend = () => {
        this.speaking = false;
      };

      // console.log(this.voice);
      window.speechSynthesis.speak(this.voice);
    }
  }

  private cancelVoice() {
    if ("speechSynthesis" in window) {
      window.speechSynthesis.cancel();
      this.speaking = false;
    }
  }

  private visitaNoticia() {
    this.$axios
      .put("noticias/visita", {
        id: this.dataNoticia.id,
      })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);
    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return (
        "Publicado el " + diaInicio + " de " + mesInicio + ", " + anhoInicio
      );
    }
  }

  private irLink() {
    var link =
      "https://web.whatsapp.com/send?l=en&text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private compartirEnTwitter() {
    const url = "https://twitter.com/intent/tweet";
    // const texto = "¡Echa un vistazo a mi contenido compartido en Twitter!";
    const hashtags = "Municipalidad,Arica";
    const compartirURL = `${url}?url=${encodeURIComponent(
      window.location.href
    )}&hashtags=${encodeURIComponent(hashtags)}`;
    window.open(compartirURL, "_blank");
  }

  private compartirEnFacebook() {
    const url = "https://www.facebook.com/sharer/sharer.php";
    const texto = "Municipalidad";
    const compartirURL = `${url}?u=${encodeURIComponent(window.location.href)}`;
    window.open(compartirURL, "_blank");
  }

  private irLinkMobile() {
    var link =
      "https://api.whatsapp.com/send?text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irEmail(titulo: string, contenido: string) {
    window.open(
      "mailto:?Subject=" +
        titulo +
        "&" +
        "Body=" +
        encodeURIComponent(window.location.href)
    );
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      if (
        this.$router.currentRoute.name == "Publicacion" &&
        this.nombreWeb != nombreWeb
      ) {
        this.$router
          .push({
            name: "Publicacion",
            params: { nombre: nombreWeb },
          })
          .catch((err) => {
            console.log(err);
          });
        this.$router.go(0);
      } else {
        this.$router
          .push({
            name: "Publicacion",
            params: { nombre: nombreWeb },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private fechaPublicacionNoticias(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }
}
