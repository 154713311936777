





import { Component, Vue } from "vue-property-decorator";
import Cultura from "@/components/cultura/Cultura.vue";

@Component({
  components: {
    Cultura
  },
  meta: {
    // sets document title
    title: "Cultura - Municipalidad de Arica",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Información cultural de la comuna."
      },
      keywords: { name: "keywords", content: "Arica cultura" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class GeneralView extends Vue {
  private mounted() {
    document.title = "Cultura - Municipalidad de Arica";
    var canonicalLink = document.createElement("link");
  // Establece los atributos del enlace

  canonicalLink.rel = "canonical"; 
  canonicalLink.href = 'https://muniarica.cl/cultura'; 
  document.head.appendChild(canonicalLink);
  }
}
