

























































import { Vue, Watch } from "vue-property-decorator";

export default class Cultura extends Vue {
  private irCategoria(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CategoriaCultura" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CategoriaCultura",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
