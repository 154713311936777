


































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Publicacion extends Vue {
  private horizontalPercentageInteres = 0;
  private nombreWeb: any = "";
  private dataCultura: any = [];
  private nombreTipo: any = "";
  private nombreBreads: any = "";
  private cargarPagina = false;
  private detalleGaleria = false;
  private slide = "";
  // galeria
  private slideIndex: number = 1;

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoCultura();
      this.linkCanonical(this.nombreWeb);
    }
  }
  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/mascotas/' + nombreWeb; 
    document.head.appendChild(canonicalLink);
  }

  private descargarDocumento(archivo) {
    window.open(process.env.VUE_APP_BASE_URL + archivo, "_blank");
  }

  private getInfoCultura() {
    this.$axios
      .get("cultura/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.dataCultura = res.data;
        if (this.dataCultura.foto) {
          this.dataCultura.foto = base + this.dataCultura.foto;
        } else {
          this.dataCultura.foto = require("@/assets/logos/logo800.webp");
        }

        for (let i = 0; i < this.dataCultura.imagenes.length; i++) {
          if (
            this.dataCultura.imagenes[i].imagen &&
            this.dataCultura.imagenes[i].imagen != ""
          ) {
            this.dataCultura.imagenes[i].imagen =
              base + this.dataCultura.imagenes[i].imagen;
            this.dataCultura.imagenes[i].imagen;
          }
        }

        // //MetaData generica
        document.title = this.dataCultura.nombre + " - Municipalidad de Arica";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataCultura.descripcion);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataCultura.foto
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataCultura.nombre + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataCultura.descripcion);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataCultura.foto);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataCultura.nombre + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataCultura.descripcion);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataCultura.foto);

        this.cargarPagina = true;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item.trim() !== "") {
      if (!item.startsWith("http://") && !item.startsWith("https://")) {
        item = "https://" + item;
      }
      window.open(item, target);
    }
  }

  // galeria

  private updated() {
    this.showSlides(this.slideIndex);
  }

  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      if (!this.$q.screen.xs) {
        var dots = document.getElementsByClassName("dot");
        for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
        }
        dots[this.slideIndex - 1].className += " active";
      }
      (slides[this.slideIndex - 1] as any).style.display = "flex";
    }
  }

  private seleccionarAbrirModal(index) {
    this.slideIndex = index;
    this.detalleGaleria = true;
  }

  // nuevo slider
  // SCROLL CARROUSEL GALERIA
  private scroll(direccion: string) {
    // console.log(this.$refs.scrollInteres);
    if (this.$refs.scrollInteres as any) {
      const size = (this.$refs.scrollInteres as any).scrollSize;
      const position = (this.$refs.scrollInteres as any).scrollPosition;
      // console.log("size", size);
      //       console.log("position", position);

      if (direccion == "derecha") {
        console.log("horizontalporcentage", this.horizontalPercentageInteres);
        if (this.horizontalPercentageInteres >= 0.9868) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position + 230,
            150
          );
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
          } else {
            (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position - 230,
            150
          );
        }
      }
    }
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }
}
