import { render, staticRenderFns } from "./Cultura.vue?vue&type=template&id=62d2d15e&scoped=true&"
import script from "./Cultura.vue?vue&type=script&lang=ts&"
export * from "./Cultura.vue?vue&type=script&lang=ts&"
import style0 from "./Cultura.vue?vue&type=style&index=0&id=62d2d15e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62d2d15e",
  null
  
)

export default component.exports